import Big from 'big.js'

import {
  TARIF_TYPES,
  IVA_MULTIPLIER,
  currencyFormatter as currency,
  PERSON_TYPES,
  CFE_TARIFS,
  CFE_INCREASE_RATE,
} from './constants'

// Points
const SPECIAL_CHARACTERS_REGEX = /\./g
const WORD_REGEX = /(?:^|\s)\S/g

function cleanName(string) {
  const capitalized = string.replace(WORD_REGEX, (word) => {
    return word.toUpperCase()
  })

  const capitalizedNoSpecialCharacters = capitalized.replace(
    SPECIAL_CHARACTERS_REGEX,
    '',
  )

  return capitalizedNoSpecialCharacters
}

function getInterestRatePercentage(tarifType, contractSigned) {
  let interestRate = 0

  if (tarifType === TARIF_TYPES.DAC && contractSigned) {
    interestRate = 19.8
  } else if (
    (tarifType === TARIF_TYPES.DAC || tarifType === TARIF_TYPES.PDBT) &&
    !contractSigned
  ) {
    interestRate = 23
  } else if (
    tarifType === TARIF_TYPES.GDMTO ||
    tarifType === TARIF_TYPES.GDMTH
  ) {
    if (!contractSigned) {
      interestRate = 20
    } else {
      interestRate = 16.7
    }
  } else {
    interestRate = 19.8
  }

  return interestRate
}

function getApertureCommission(tarifType, remainingSmall) {
  try {
    const remaining = Big(remainingSmall)

    // NOTE: Changed to 35% due client requets
    const percentage = 0.035

    const amount = remaining
      .times(percentage)
      .times(IVA_MULTIPLIER)
      .toFixed(2)

    return [Number(amount), percentage]
  } catch (error) {
    return [0, 0]
  }
}

function getAmortizationPayments({
  monthlyPayment: smallMonthlyPayment,
  annualInterestRate,
  originalBalance,
  monthlyTerm,
  personType,
}) {
  const payments = []

  const monthlyPayment = Big(smallMonthlyPayment)
  const monthlyInterestRate = annualInterestRate / 12

  let balance = new Big(originalBalance)

  for (let i = 0; i < monthlyTerm; i += 1) {
    const interest = balance.times(monthlyInterestRate)
    const iva = personType === 'pf' ? interest.times(0.16) : 0
    const principal = monthlyPayment.minus(iva).minus(interest)

    payments.push({
      month: i + 1,
      interest: currency.format(interest),
      principal: currency.format(principal),
      initialBalance: currency.format(balance),
      finalBalance: currency.format(Math.abs(Number(balance.minus(principal)))),
      iva: currency.format(iva),
      paymentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 2 + i,
        0,
      ).toLocaleDateString('es-MX'),
      monthlyPayment: currency.format(monthlyPayment),
    })

    balance = balance.minus(principal)
  }

  return payments
}

function getSavings({
  electricityCost: initialElectricityCost,
  clientPersonType,
  loanHook,
  loanMonthlyPayment,
  loanAnnualTerm,
  systemCost: smallSystemCost,
  systemAnnualProduction: smallSystemAnnualProduction,
  systemTarifType,
  systemCapacity,
}) {
  const yearly = []

  const loanAnnualPayment = loanMonthlyPayment * 12
  const systemCost = Big(Number(smallSystemCost))
  const systemAnnualProduction = Big(smallSystemAnnualProduction)

  for (let year = 0; year < 20; year += 1) {
    let deductions = 0
    let incomeByCels = 0
    let annualPayment = 0
    const electricityCost =
      year > 0
        ? yearly[year - 1].electricityCost * (1 + CFE_INCREASE_RATE)
        : initialElectricityCost

    if (year === 0) {
      if (
        clientPersonType === PERSON_TYPES.PM ||
        clientPersonType === PERSON_TYPES.PFAE
      ) {
        // TODO: set 0.3 as constant
        deductions = Number(systemCost.div(IVA_MULTIPLIER).times(0.3))
      }
    }

    if (year >= 0 && year <= 7) {
      if (
        (systemTarifType === TARIF_TYPES.GDMTH ||
          systemTarifType === TARIF_TYPES.GDMTO) &&
        systemCapacity >= 450
      ) {
        incomeByCels = Number(
          systemAnnualProduction.div(1000).times(CFE_TARIFS.CELS),
        )
      }
    }

    if (year < loanAnnualTerm) {
      annualPayment = loanAnnualPayment
    }

    const approximateSavings =
      electricityCost - annualPayment + deductions + incomeByCels

    let investmentPerformancePercentage
    if (loanHook > 0) {
      investmentPerformancePercentage = Number(
        Big(approximateSavings)
          .div(loanHook)
          .times(100),
      )
    } else {
      investmentPerformancePercentage = 'n.a.'
    }

    yearly.push({
      electricityCost,
      deductions,
      incomeByCels,
      annualPayment,
      approximateSavings,
      investmentPerformancePercentage,
    })
  }

  const totalApproximateSavings = yearly.reduce(
    (benefits, { approximateSavings }) => benefits + approximateSavings,
    0,
  )
  const first8Years = yearly.slice(0, 8)

  let first8YearsPerformanceSum
  if (typeof investmentPerformancePercentage === 'string') {
    first8YearsPerformanceSum = 'n.a.'
  } else
    first8YearsPerformanceSum = first8Years.reduce(
      (sum, { investmentPerformancePercentage }) =>
        investmentPerformancePercentage + sum,
      0,
    )

  let average8YearsPerformancePercentage
  if (typeof first8YearsPerformanceSum === 'string') {
    average8YearsPerformancePercentage = 'n.a.'
  } else {
    average8YearsPerformancePercentage =
      first8YearsPerformanceSum / first8Years.length
  }

  return {
    yearly,
    totalApproximateSavings,
    average8YearsPerformancePercentage,
  }
}

export {
  getSavings,
  getInterestRatePercentage,
  getApertureCommission,
  cleanName,
  getAmortizationPayments,
}
