import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Box, Heading, DataTable, Button } from 'grommet'
import { DocumentPdf } from 'grommet-icons'

import { UserContext } from '../Main'
import api from '../../utils/api'
import Spinner from '../../components/Spinner'
import { currencyFormatter } from '../../utils/suneco-loan.js/constants'

function Clients() {
  const [quotes, setQuotes] = useState(null)
  const user = useContext(UserContext)

  const getQuotes = useCallback(() => {
    api
      .getQuotes()
      .then(({ quotes: quotesData }) => setQuotes(quotesData))
      .catch((error) => setQuotes(error))
  }, [])

  useEffect(() => {
    getQuotes()
  }, [getQuotes])

  const isAdmin = user.app_metadata && user.app_metadata.admin

  return (
    <Box pad="large">
      <Heading>Clientes</Heading>
      <Heading level={4}>Tabla de cotizaciones</Heading>
      {quotes === null && <Spinner />}
      {quotes &&
        (quotes.length > 0 ? (
          <Box style={{ overflow: 'scroll' }} elevation="medium">
            <DataTable
              columns={[
                { property: 'createdByCount', header: 'No.', primary: true },
                {
                  property: 'client.name',
                  header: 'Nombre del cliente',
                  search: true,
                },
                ...(isAdmin
                  ? [
                      {
                        property: 'client.city',
                        header: 'Municipio',
                        search: true,
                      },
                      {
                        property: 'createdBy',
                        header: 'Instalador',
                        render: ({ createdBy }) => (
                          <Link to={`/installers/${createdBy}`}>
                            {createdBy}
                          </Link>
                        ),
                        search: true,
                      },
                      {
                        property: 'total',
                        header: 'Monto cotizado',
                        render: ({ total }) => currencyFormatter.format(total),
                      },
                    ]
                  : []),
                {
                  property: 'createdAt',
                  header: 'Fecha',
                  render: ({ createdAt }) =>
                    new Date(createdAt).toLocaleDateString('es-MX'),
                },
                {
                  property: 'status',
                  header: 'Estatus',
                  render: () => 'Cotizado',
                },
                {
                  property: 'file',
                  header: 'Archivo',
                  render: (quote) => (
                    <a
                      href={`${window.origin}/api/v1/quotes/${
                        quote.id
                      }.pdf?authorization=${localStorage.getItem('@idToken')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DocumentPdf />
                    </a>
                  ),
                },
              ]}
              pad={{ horizontal: 'medium', vertical: 'small' }}
              background={{
                body: ['#fff', '#f6f6f6'],
              }}
              data={quotes}
              sortable
            />
          </Box>
        ) : (
          <>
            <Heading level={5}>No se han creado cotizaciones</Heading>
            <Button alignSelf="start" label="Recargar" onClick={getQuotes} />
          </>
        ))}
    </Box>
  )
}

export default Clients
