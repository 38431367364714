import React, { useEffect, useState, useCallback } from 'react'
import {
  Box,
  Heading,
  CheckBox,
  DataTable,
  Button,
  FormField,
  TextInput,
} from 'grommet'
import { DocumentPdf } from 'grommet-icons'
import { useParams } from 'react-router'

import api from '../../utils/api'
import Spinner from '../../components/Spinner'
import { currencyFormatter } from '../../utils/suneco-loan.js/constants'

function User() {
  const { id: userId } = useParams()

  const [user, setUser] = useState(null)
  const [quotes, setQuotes] = useState(null)
  const [upatingUser, setUpdatingUser] = useState(false)

  const getUser = useCallback(() => {
    api
      .getUser(userId)
      .then(({ user: userData }) => {
        setUser({
          user_id: userData.user_id,
          name: userData.name,
          blocked: userData.blocked,
          email: userData.email,
          phone:
            (userData.user_metadata && userData.user_metadata.phone_number) ||
            null,
          company:
            (userData.user_metadata && userData.user_metadata.company) || null,
        })
      })
      .catch(setUser)
  }, [userId])

  useEffect(() => {
    getUser()
  }, [getUser])

  const getQuotes = useCallback(() => {
    setQuotes(null)

    api
      .getQuotes({ user: userId })
      .then(({ quotes: quoteData }) => setQuotes(quoteData))
      .catch(setUser)
  }, [userId])

  useEffect(() => {
    getQuotes()
  }, [getQuotes])

  function handleChange({ target: { name, value, type, checked } }) {
    setUser((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? !checked : value,
      updated: true,
    }))
  }

  function handleSave() {
    setUpdatingUser(true)

    api
      .patchUser(user.user_id, {
        name: user.name,
        blocked: user.blocked,
        email: user.email,
        phone: user.phone,
        company: user.company,
      })
      .then(() => {
        setUser(null)
        getUser()
        setUpdatingUser(false)
      })
      .catch(() => setUpdatingUser(false))
  }

  function handleCancel() {
    setUser(null)
    getUser()
  }

  return (
    <Box flex={{ grow: 1 }} pad="large">
      <Box direction="row" justify="between" align="center">
        <Heading>Información del instalador</Heading>
      </Box>
      <Heading level={5}>Datos</Heading>
      {user === null && <Spinner />}
      {user instanceof Error && 'Error'}
      {user && (
        <>
          <Box
            direction="row"
            pad="medium"
            elevation="medium"
            justify="between"
          >
            <Box>
              <Box margin={{ top: 'small', bottom: 'medium' }}>
                <CheckBox
                  checked={!!user.blocked !== true}
                  label="Usuario activo"
                  name="blocked"
                  onChange={handleChange}
                />
              </Box>
              <FormField label="Nombre">
                <TextInput
                  name="name"
                  onChange={handleChange}
                  value={user.name}
                />
              </FormField>
              <FormField label="Correo">
                <TextInput value={user.email} />
              </FormField>
              <FormField label="Teléfono">
                <TextInput
                  name="phone"
                  onChange={handleChange}
                  value={user.phone}
                />
              </FormField>
              <FormField label="Empresa">
                <TextInput
                  name="company"
                  onChange={handleChange}
                  value={user.company}
                />
              </FormField>
            </Box>
            {user.updated && (
              <Box direction="row" gap="small" alignSelf="start">
                <Button
                  label="Cancelar"
                  onClick={handleCancel}
                  alignSelf="start"
                />
                <Button
                  disabled={upatingUser}
                  label={upatingUser ? 'Cargando...' : 'Guardar'}
                  onClick={handleSave}
                  alignSelf="start"
                  primary
                />
              </Box>
            )}
          </Box>
        </>
      )}
      <Heading level={5}>Cotizaciones</Heading>
      {quotes === null && <Spinner />}
      {quotes &&
        (quotes.length > 0 ? (
          <Box elevation="medium" style={{ overflow: 'scroll' }}>
            <DataTable
              columns={[
                { property: 'createdByCount', header: 'No.', primary: true },
                {
                  property: 'client.name',
                  header: 'Nombre del cliente',
                  search: true,
                },
                {
                  property: 'client.city',
                  header: 'Municipio',
                },
                {
                  property: 'total',
                  header: 'Monto cotizado',
                  render: ({ total }) => currencyFormatter.format(total),
                },
                {
                  property: 'createdAt',
                  header: 'Fecha',
                  render: ({ createdAt }) =>
                    new Date(createdAt).toLocaleDateString('es-MX'),
                },
                {
                  property: 'status',
                  header: 'Estatus',
                  render: () => 'Cotizado',
                },
                {
                  property: 'file',
                  header: 'Archivo',
                  render: (quote) => (
                    <a
                      href={`${window.origin}/api/v1/quotes/${
                        quote.id
                      }.pdf?authorization=${localStorage.getItem('@idToken')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DocumentPdf />
                    </a>
                  ),
                },
              ]}
              pad={{ horizontal: 'medium', vertical: 'small' }}
              background={{
                body: ['#fff', '#f6f6f6'],
              }}
              data={quotes}
            />
          </Box>
        ) : (
          <>
            <Heading level={5}>No se han creado cotizaciones</Heading>
            <Button label="Recargar" alignSelf="start" onClick={getQuotes} />
          </>
        ))}
    </Box>
  )
}

export default User
