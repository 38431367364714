/*
  T A R I F   T Y P E S
*/
const DAC = 'DAC'
const PDBT = 'PDBT'
const GDMTO = 'GDMTO'
const GDMTH = 'GDMTH'
const TARIF_TYPES = {
  DAC,
  PDBT,
  GDMTO,
  GDMTH,
}
const ALL_TARIF_TYPES = [DAC, PDBT, GDMTO, GDMTH]

/*
  P E R S O N   T Y P E S
*/
const PM = 'pm'
const PF = 'pf'
const PFAE = 'pfae'
const PERSON_TYPES = {
  PM,
  PF,
  PFAE,
}
const ALL_PERSON_TYPES = [PM, PF, PFAE]
function getPersonTypeLabel(key) {
  switch (key) {
    case PM:
      return 'Persona moral'
    case PF:
      return 'Persona física'
    case PFAE:
      return 'Persona física con actividad empresarial'
    default:
      return 'Inválido'
  }
}

/*
  L O A N   T E R M S
*/
const LOAN_TERMS = [1, 2, 3, 4, 5]

/*
  I V A
*/
const IVA_MULTIPLIER = 1.16

/*
  C F E
*/
const CFE_INCREASE_RATE = 0.04
const CFE_TARIFS = { DAC: 4.8, PDBT: 3.3, GDMTO: 1.6, GDMTH: 1.6, CELS: 120 }

/*
  C U R R E N C Y
*/

const defaultLocale = 'es-MX'
const maxDecimals = 2

const currencyFormatter = {
  format: (number) => {
    if (isNaN(Number(number))) {
      return number
    }

    return `$${Number(Number(number).toFixed(maxDecimals)).toLocaleString(
      defaultLocale,
    )}`
  },
}

export {
  IVA_MULTIPLIER,
  LOAN_TERMS,
  TARIF_TYPES,
  ALL_TARIF_TYPES,
  PERSON_TYPES,
  ALL_PERSON_TYPES,
  currencyFormatter,
  CFE_INCREASE_RATE,
  CFE_TARIFS,
  getPersonTypeLabel,
}
