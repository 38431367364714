import React from 'react'
import { DataTable } from 'grommet'

import { currencyFormatter as currency } from '../../../utils/suneco-loan.js/constants'

function AnnualFlow({
  monthlyPayment,
  monthlyTerm,
  annualCfeSavings,
  savings,
}) {
  const years = 20
  const paymentYears = monthlyTerm / 12
  const annualFlow = []

  const yearlySavings = savings.yearly

  for (let i = 0; i < years; i += 1) {
    const yearSaving = yearlySavings[i]

    annualFlow.push({
      incomeByCels: currency.format(yearSaving.incomeByCels),
      deductions: currency.format(yearSaving.deductions),
      approximateSavings: currency.format(yearSaving.approximateSavings),
      investmentPerformancePercentage: `${
        typeof yearSaving.investmentPerformancePercentage === 'string'
          ? yearSaving.investmentPerformancePercentage
          : `${Number(yearSaving.investmentPerformancePercentage).toFixed(2)}%`
      }`,
      electricityCost: currency.format(yearSaving.electricityCost),
      year: i + 1,
      annualPayment: currency.format(
        paymentYears > i ? monthlyPayment * 12 : 0,
      ),
    })
  }

  return (
    <DataTable
      columns={[
        { property: 'year', header: 'Año', primary: true },
        { property: 'electricityCost', header: 'Costo electricidad c. IVA' },
        { property: 'annualPayment', header: 'Pago financiamiento' },
        { property: 'deductions', header: 'Deducciones' },
        { property: 'incomeByCels', header: "Ingresos por CEL's" },
        { property: 'approximateSavings', header: 'Ahorro aproximado' },
        {
          property: 'investmentPerformancePercentage',
          header: 'Rendimiento anual sobre inversión',
        },
      ]}
      pad={{ horizontal: 'medium', vertical: 'small' }}
      background={{
        body: ['#fff', '#f6f6f6'],
      }}
      data={annualFlow}
    />
  )
}

export default AnnualFlow
