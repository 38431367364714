import React from 'react'
import { DataTable } from 'grommet'

import { getAmortizationPayments } from '../../../utils/suneco-loan.js/functions'

function AmortizatonTable({
  monthlyTerm,
  monthlyPayment,
  total: originalBalance,
  annualInterestRate,
  personType,
}) {
  const payments = getAmortizationPayments({
    monthlyPayment,
    annualInterestRate,
    originalBalance,
    monthlyTerm,
    personType,
  })

  return (
    <DataTable
      columns={[
        { property: 'month', header: 'Mes', primary: true },
        { property: 'paymentDate', header: 'Fecha de corte' },
        { property: 'initialBalance', header: 'Saldo inicial' },
        { property: 'principal', header: 'Capital' },
        { property: 'finalBalance', header: 'Saldo final' },
        { property: 'interest', header: 'Interés' },
        { property: 'iva', header: 'IVA' },
        { property: 'monthlyPayment', header: 'Pago mensual' },
      ]}
      pad={{ horizontal: 'medium', vertical: 'small' }}
      background={{
        body: ['#fff', '#f6f6f6'],
      }}
      data={payments}
    />
  )
}

export default AmortizatonTable
