import styled from 'styled-components'
import { Box } from 'grommet'

const StyledCollapsible = styled(Box)`
  overflow: hidden;
  position: relative;
  transition: 0.5s max-height;
  max-height: ${({ expanded }) => (expanded ? '80vh' : '40px')};

  @media (min-width: 600px) {
    max-height: ${({ expanded }) => (expanded ? '80vh' : '70px')};
    width: ${({ half }) => half && '50%'};
  }
`

export { StyledCollapsible }
