import React, { useState, useContext, useCallback, useEffect } from 'react'
import {
  Box,
  Heading,
  Button,
  TextInput,
  FormField,
  Text,
  Select,
  CheckBox,
  Form as GrommetForm,
} from 'grommet'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MaskedInput from 'react-text-mask'

import api from '../../utils/api'
import { UserContext } from '../Main'
import { getMonthlyPayment } from '../../utils/suneco-loan.js/loan'
import MEXICO_STATES from '../../utils/suneco-loan.js/MEXICO_STATES.json'
import createNumberMask from '../../utils/numberMask'

import {
  TARIF_TYPES,
  ALL_TARIF_TYPES,
  ALL_PERSON_TYPES,
  LOAN_TERMS,
  currencyFormatter,
  getPersonTypeLabel,
} from '../../utils/suneco-loan.js/constants'
import {
  getApertureCommission,
  getInterestRatePercentage,
  getSavings,
} from '../../utils/suneco-loan.js/functions'

const initialState = ({ client = {}, loan = {}, system = {} } = {}) => ({
  currentTarifType: system.tarifType || TARIF_TYPES.DAC,
  systemCapacity: system.capacity || '',
  systemCost: system.cost || '',
  systemAnnualProduction: system.annualProduction || '',
  loanHookPercentage: loan.hookPercentage || 30,
  loanYearlyTerm:
    (system.monthlyTerm && system.monthlyTerm / 12) ||
    LOAN_TERMS[LOAN_TERMS.length - 1],
  annualCfeSavings: system.annualCfeSavings || '',
  contractSignedWithInstaller: loan.contractSigned || false,
  clientPersonType: client.personType || '',
  clientName: client.name || '',
  clientCity: client.city || '',
  clientEmail: client.email || '',
  clientState: client.state || '',
  clientZipCode: client.zipCode || '',
})

const InputLabel = styled.label`
  font-size: 15px;
  line-height: 20px;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;

  span {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 20px;
  }

  input {
    box-sizing: border-box;
    font-size: inherit;
    font-family: inherit;
    -webkit-appearance: none;
    padding: 9px;
    outline: none;
    background: transparent;
    color: inherit;
    font-weight: 600;
    margin: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.33);
    border-radius: 0;
    width: 100%;
    &::placeholder {
      opacity: 0.2;
    }
  }
`

const ResponsiveBox = styled(Box)`
  > div:nth-child(1),
  > div:nth-child(3) {
    width: 100%;
  }

  @media (min-width: 600px) {
    flex-direction: row;

    > div:nth-child(1),
    > div:nth-child(3) {
      width: 50%;
    }
    >div: nth-child(2) {
      width: 20px;
    }
  }
`

function Form({ onCalculate, error, data }) {
  const [fields, setFields] = useState(initialState(data))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [error])

  const user = useContext(UserContext)

  const annualInterestRate =
    getInterestRatePercentage(
      fields.currentTarifType,
      fields.contractSignedWithInstaller,
      fields.clientPersonType.value,
    ) / 100

  const monthlyTerm = fields.loanYearlyTerm * 12

  const hookTotal = fields.systemCost * (fields.loanHookPercentage / 100)

  const loanBalance = (fields.systemCost || 0) - hookTotal

  const monthlyPayment = useCallback(
    getMonthlyPayment({
      balance: loanBalance,
      annualInterest: annualInterestRate,
      months: monthlyTerm,
      personType: fields.clientPersonType.value,
    }),
    [
      loanBalance,
      annualInterestRate,
      monthlyTerm,
      fields.clientPersonType.value,
    ],
  )

  function handleNumberChange({ target: { name, value: rawValue } }) {
    const value = rawValue.replace(/,|\$|[A-Za-z]/g, '')

    setFields((prev) => ({ ...prev, [name]: value }))
  }

  function handleChange({ target: { name, value, checked, type } }) {
    setFields((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const [
    openingCommission,
    openingCommissionPercentage,
  ] = getApertureCommission(fields.currentTarifType, loanBalance)

  function calculate() {
    const client = {
      name: fields.clientName,
      personType: fields.clientPersonType.value,
      city: fields.clientCity,
      email: fields.clientEmail,
      state: fields.clientState,
      zipCode: fields.clientZipCode,
    }

    const system = {
      tarifType: fields.currentTarifType,
      capacity: fields.systemCapacity,
      cost: fields.systemCost,
      annualProduction: fields.systemAnnualProduction,
      annualCfeSavings: fields.annualCfeSavings,
    }

    const loan = {
      annualInterestRate,
      monthlyTerm,
      total: loanBalance,
      monthlyPayment,
      hookPercentage: fields.loanHookPercentage,
      contractSigned: fields.contractSignedWithInstaller,
      // Locally calculated
      openingCommission,
      openingCommissionPercentage,
      hookTotal,
    }

    const savings = getSavings({
      electricityCost: fields.annualCfeSavings,
      clientPersonType: client.personType,
      loanHook: loan.hookTotal,
      loanMonthlyPayment: loan.monthlyPayment,
      loanAnnualTerm: loan.monthlyTerm / 12,
      systemCost: system.cost,
      systemAnnualProduction: system.annualProduction,
      systemTarifType: system.tarifType,
      systemCapacity: system.capacity,
    })

    setLoading(true)
    onCalculate({ client, system, loan, savings })
  }

  function handleZipCodeChange({ target: { value } }) {
    setFields((prev) => ({ ...prev, clientZipCode: value }))

    if (value.length === 5) {
      api
        .getAddressFrom({ zipCode: value })
        .then(({ colonias, estado, municipio }) => {
          if (municipio && estado) {
            setFields((prev) => ({
              ...prev,
              clientState: estado,
              clientCity: municipio,
            }))
          }
        })
    }
  }

  return (
    <GrommetForm onSubmit={calculate}>
      <ResponsiveBox
        direction="column"
        gap="medium"
        margin={{ bottom: 'medium' }}
      >
        <Box flex={{ grow: 1 }}>
          <Heading level="4">Datos cliente</Heading>
          <FormField
            value={user.name}
            placeholder="Requerido"
            label="Instalador"
            readOnly
          />
          <FormField
            onChange={handleChange}
            name="clientName"
            value={fields.clientName}
            placeholder="John Doe"
            label="Nombre completo"
            validate={{ regexp: /^[a-z]/i, message: 'Requerido' }}
          />
          <FormField
            label="Correo"
            onChange={handleChange}
            name="clientEmail"
            value={fields.clientEmail}
            placeholder="john@mail.com"
            validate={{
              regexp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Introduce un correo válido',
            }}
          />
          <FormField
            label="C.P."
            onChange={handleZipCodeChange}
            name="clientZipCode"
            value={fields.clientZipCode}
            placeholder="00000"
            validate={{
              regexp: /\d{5}/g,
              message: 'Introduce un código postal válido',
            }}
          />
          <FormField label="Estado">
            <Select
              options={MEXICO_STATES.map(({ name }) => name)}
              value={fields.clientState}
              placeholder="Seleccionar"
              onChange={({ option }) =>
                setFields((prev) => ({ ...prev, clientState: option }))
              }
            />
          </FormField>

          <InputLabel htmlFor="clientCity">
            <span>Municipio</span>
            <input
              id="clientCity"
              name="clientCity"
              type="text"
              onChange={handleChange}
              placeholder="Municipio"
              value={fields.clientCity}
            />
          </InputLabel>
        </Box>
        <Box flex={{ grow: 1 }}>
          <Heading level="4">Datos del sistema fotovoltaico</Heading>
          <InputLabel htmlFor="systemCapacity">
            <span>Capacidad del sistema (kW)</span>
            <MaskedInput
              mask={createNumberMask({ allowDecimal: true, prefix: '' })}
              maskPlaceholder="0"
              onChange={handleNumberChange}
              name="systemCapacity"
              id="systemCapacity"
              value={fields.systemCapacity}
              placeholder="0"
              guide={false}
            />
          </InputLabel>
          <InputLabel htmlFor="systemAnnualProduction">
            <span>Producción anual del sistema (kWh)</span>
            <MaskedInput
              mask={createNumberMask({ allowDecimal: true, prefix: '' })}
              maskPlaceholder="0"
              onChange={handleNumberChange}
              name="systemAnnualProduction"
              id="systemAnnualProduction"
              value={fields.systemAnnualProduction}
              placeholder="0"
              guide={false}
            />
          </InputLabel>
          <InputLabel htmlFor="annualCfeSavings">
            <span>Ahorro en recibo CFE anual c. IVA</span>
            <MaskedInput
              mask={createNumberMask({ allowDecimal: true })}
              maskPlaceholder="0"
              id="annualCfeSavings"
              name="annualCfeSavings"
              onChange={handleNumberChange}
              value={fields.annualCfeSavings}
              placeholder="0"
              guide={false}
            />
          </InputLabel>
          <InputLabel htmlFor="systemCost">
            <span>Precio del sistema c. IVA</span>
            <MaskedInput
              mask={createNumberMask({ allowDecimal: true })}
              maskPlaceholder=""
              onChange={handleNumberChange}
              value={fields.systemCost}
              type="text"
              id="systemCost"
              name="systemCost"
              placeholder="0"
              guide={false}
            />
          </InputLabel>
          <FormField label="Tipo de tarifa CFE">
            <Select
              options={ALL_TARIF_TYPES}
              value={fields.currentTarifType}
              onChange={({ option }) =>
                setFields((prev) => ({ ...prev, currentTarifType: option }))
              }
            />
          </FormField>
          <CheckBox
            name="contractSignedWithInstaller"
            onChange={handleChange}
            checked={fields.contractSignedWithInstaller}
            label="Contrato convenio firmado con instalador"
          />
        </Box>
      </ResponsiveBox>
      <Heading level="4">Propuesta de financiamiento</Heading>
      <ResponsiveBox direction="column" gap="medium" align="center">
        <Box flex={{ grow: 1 }}>
          <FormField label="Tipo de acreditado">
            <Select
              options={ALL_PERSON_TYPES.map((value) => ({
                value,
                label: getPersonTypeLabel(value),
              }))}
              labelKey="label"
              valueKey="value"
              value={fields.clientPersonType.label}
              placeholder="Seleccionar"
              onChange={({ option }) =>
                setFields((prev) => ({ ...prev, clientPersonType: option }))
              }
            />
          </FormField>
          <FormField label="Porcentaje de enganche">
            <TextInput
              name="loanHookPercentage"
              onChange={handleNumberChange}
              value={fields.loanHookPercentage}
              placeholder="30"
              validate={{
                regexp: /^\d+\.\d{0,2}$/g,
                message: 'Introducir cantidad válida',
              }}
            />
          </FormField>
          <FormField label="Plazo (años)">
            <Select
              options={LOAN_TERMS}
              value={fields.loanYearlyTerm}
              onChange={({ option }) =>
                setFields((prev) => ({ ...prev, loanYearlyTerm: option }))
              }
            />
          </FormField>
        </Box>
        <Box pad="medium">
          <Text>
            <strong>Enganche: </strong>
            {currencyFormatter.format(hookTotal)}
          </Text>
          <Text>
            <strong>Tasa: </strong>
            {annualInterestRate * 100}%
          </Text>
          <Text>
            <strong>Comisión por apertura c. IVA: </strong>
            {currencyFormatter.format(openingCommission)}
          </Text>
          <Text>
            <strong>Capital a financiar: </strong>
            {currencyFormatter.format(loanBalance)}
          </Text>
          <Text>
            <strong>Pago mensual: </strong>
            {currencyFormatter.format(monthlyPayment)}
          </Text>
        </Box>
      </ResponsiveBox>
      <Box justify="center" align="center">
        <Button
          style={{ maxWidth: 200, width: '100%' }}
          disabled={loading}
          margin={{ top: 'medium' }}
          label={loading ? 'Cargando...' : 'Calcular'}
          type="submit"
          primary
        />
      </Box>
    </GrommetForm>
  )
}

Form.propTypes = {
  onCalculate: PropTypes.func.isRequired,
}

export default Form
