import React from 'react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import LogRocket from 'logrocket'

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_CLIENT_ID,
  beforeSend: (data) => {
    data.metaData.sessionURL = LogRocket.sessionURL
    return data
  },
})

bugsnagClient.use(bugsnagReact, React)

export default bugsnagClient
