import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import {
  Box,
  Heading,
  Button,
  DataTable,
  Layer,
  FormField,
  TextInput,
  Form,
  Paragraph,
} from 'grommet'

import Spinner from '../../components/Spinner'
import api from '../../utils/api'

function CreateUserModal() {
  const history = useHistory()
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const [fields, setFields] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    company: '',
  })

  function handleChange({ target: { name, value } }) {
    setError(null)
    setFields((prev) => ({ ...prev, [name]: value }))
  }

  async function handleSubmit(event) {
    event.preventDefault()

    setError(null)
    setLoading(true)

    try {
      await api.createUser(fields)

      history.replace('/')
    } catch ($0) {
      setLoading(false)
      setError($0)
    }
  }

  return (
    <Layer
      onClickOutside={() => history.replace('/')}
      onEsc={() => history.replace('/')}
    >
      <Form onSubmit={handleSubmit}>
        <Box pad="medium">
          <Box direction="row" justify="between" align="center">
            <Heading level="3">Nuevo instalador</Heading>
            <Button
              label="Cancelar"
              onClick={() => history.replace('/')}
              plain
            />
          </Box>
          <Box direction="row" gap="medium">
            <FormField label="Nombre completo">
              <TextInput
                value={fields.name}
                name="name"
                onChange={handleChange}
                placeholder="Nombre completo"
              />
            </FormField>
            <FormField label="Correo electrónico">
              <TextInput
                value={fields.email}
                name="email"
                onChange={handleChange}
                placeholder="Correo electrónico"
              />
            </FormField>
          </Box>
          <Box direction="row" gap="medium" margin={{ bottom: 'medium' }}>
            <FormField label="Empresa">
              <TextInput
                value={fields.company}
                name="company"
                onChange={handleChange}
                placeholder="Empresa"
              />
            </FormField>
            <FormField label="Teléfono">
              <TextInput
                value={fields.phoneNumber}
                name="phoneNumber"
                onChange={handleChange}
                placeholder="Teléfono"
              />
            </FormField>
          </Box>
          {error && <Paragraph>Error al registrar instalador</Paragraph>}
          <Button
            disabled={loading}
            label={loading ? 'Cargando...' : 'Agregar'}
            type="submit"
            primary
          />
        </Box>
      </Form>
    </Layer>
  )
}

function Users() {
  const { hash } = useLocation()

  const [users, setUsers] = useState(null)
  const [error, setError] = useState(null)
  const isAddingUser = hash === '#new'

  useEffect(() => {
    api
      .getUsers()
      .then(({ users: usersData }) => setUsers(usersData))
      .catch(($0) => {
        setError($0)
      })
  }, [])

  return (
    <Box flex={{ grow: 1 }} pad="large">
      {isAddingUser && <CreateUserModal />}
      <Box direction="row" justify="between" align="center">
        <Heading>Instaladores</Heading>
        <Button
          as={Link}
          to="/installers/#new"
          label="Agregar"
          replace
          primary
        />
      </Box>
      {users === null && !error && <Spinner />}
      {users && users.length && (
        <Box overflow="scroll" elevation="medium">
          <DataTable
            columns={[
              { property: 'name', header: 'Nombre' },
              { property: 'user_metadata.company', header: 'Empresa' },
              {
                property: 'created_at',
                header: 'Fecha',
                render: ({ created_at }) =>
                  new Date(created_at).toLocaleDateString('es-MX'),
              },
              {
                property: 'user_id',
                header: 'Detalle',
                render: ({ user_id }) => (
                  <Link to={`/installers/${user_id}`}>...</Link>
                ),
              },
            ]}
            data={users}
            pad={{ horizontal: 'medium', vertical: 'small' }}
            background={{
              body: ['#fff', '#f6f6f6'],
            }}
          />
        </Box>
      )}
      {error && (
        <Box>
          <Paragraph>Error al obtener instaladores</Paragraph>
        </Box>
      )}
    </Box>
  )
}

export default Users
