import React, { useState } from 'react'
import {
  Box,
  Heading,
  Text,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  DataTable,
  Layer,
  Paragraph,
} from 'grommet'
import { FormPreviousLink, Checkmark, Alert } from 'grommet-icons'
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from 'recharts'
import PropTypes from 'prop-types'

import {
  currencyFormatter as currency,
  CFE_INCREASE_RATE,
  CFE_TARIFS,
} from '../../../utils/suneco-loan.js/constants'
import api from '../../../utils/api'
import CollapsibleCard from '../../../components/CollapsibleCard'
import ResponsiveBox from '../../../components/ResponsiveBox'

import AnnualFlow from './AnnualFlow'
import AmortizatonTable from './AmortizatonTable'

function Overview({
  onResetQuote,
  onGoBack,
  onResetQuoteSameClient,
  data = {},
}) {
  const [quote, setQuote] = useState(null)
  const [sendEmailStatus, setSendEmailStatus] = useState(null)

  function handleSendQuote() {
    setSendEmailStatus('LOADING')

    api
      .sendQuote(quote.id)
      .then(() => {
        setSendEmailStatus('SUCCESS')
      })
      .catch((error) => {
        setSendEmailStatus('FAIL')
      })
  }

  async function handleCreateQuote() {
    setQuote('LOADING')

    try {
      const { quote: quoteData } = await api.setQuote({
        client: data.client,
        system: data.system,
        loan: data.loan,
      })

      setQuote(quoteData)
    } catch (error) {
      setQuote('ERROR')
    }
  }

  const chartData = data.savings.yearly.map(
    ({ electricityCost, annualPayment }, index) => {
      return {
        label: `Año ${index + 1}`,
        name: index + 1,
        cfe: electricityCost,
        financiamiento: annualPayment,
      }
    },
  )

  return (
    <Box gap="small">
      {sendEmailStatus && sendEmailStatus !== 'LOADING' && (
        <Layer
          onEsc={() => setSendEmailStatus(null)}
          onClickOutside={() => setSendEmailStatus(null)}
        >
          <Box justify="center" pad="medium" gap="medium">
            {sendEmailStatus === 'SUCCESS' && (
              <>
                <Checkmark
                  style={{ alignSelf: 'center' }}
                  color="#61BD6C"
                  size="medium"
                />
                <Heading textAlign="center" level={3}>
                  Correo enviado
                </Heading>
                <Paragraph margin={{ top: 0 }} textAlign="center">
                  ¿Deseas crear otra corización?
                </Paragraph>
              </>
            )}
            {sendEmailStatus === 'FAIL' && (
              <>
                <Alert
                  style={{ alignSelf: 'center' }}
                  color="#EE6B60"
                  size="medium"
                />
                <Heading textAlign="center" level={3}>
                  Error al enviar correo
                </Heading>
                <Paragraph margin={{ top: 0 }} textAlign="center">
                  Por favor intenta nuevamente
                </Paragraph>
              </>
            )}
            {sendEmailStatus === 'FAIL' ? (
              <Button
                label="Reintentar"
                onClick={() => setSendEmailStatus(null)}
                primary
              />
            ) : (
              <>
                <Box justify="between" gap="small" direction="row">
                  <Button
                    label="Crear nueva mismo cliente"
                    onClick={onResetQuoteSameClient}
                  />
                  <Button label="Crear nueva" onClick={onResetQuote} primary />
                </Box>
              </>
            )}
          </Box>
        </Layer>
      )}
      <Button
        icon={<FormPreviousLink color="brand" />}
        label="Regresar"
        alignSelf="start"
        onClick={onGoBack}
        plain
      />
      <Heading level="2">Propuesta de financiamiento</Heading>
      <ResponsiveBox gap="small" direction="column">
        <Box style={{ flexShrink: 0 }} pad="small" round="xsmall">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell scope="col">Fecha</TableCell>
                <TableCell>{new Date().toLocaleDateString('es-MX')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="col">Nombre completo</TableCell>
                <TableCell>{data.client.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="col">Ubicación</TableCell>
                <TableCell>{`${data.client.city} ${data.client.zipCode}, ${data.client.state}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="col">Producto</TableCell>
                <TableCell>Crédito solar</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <ResponsiveContainer width="99%" aspect={3}>
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              formatter={(value) => currency.format(value)}
              labelFormatter={(year) => `Año ${year}`}
            />
            <Area
              type="monotone"
              dataKey="cfe"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            <Area
              type="monotone"
              dataKey="financiamiento"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </ResponsiveBox>
      <ResponsiveBox gap="small" direction="column">
        <CollapsibleCard heading="Sistema" half pad="small">
          <Box>
            <DataTable
              columns={[{ property: 'head' }, { property: 'val' }]}
              pad={{ horizontal: 'medium', vertical: 'small' }}
              background={{ body: ['#fff', '#f6f6f6'] }}
              data={[
                { head: 'Capacidad punta (kWp)', val: data.system.capacity },
                {
                  head: 'Precio del sistema c. IVA',
                  val: currency.format(data.system.cost),
                },
                { head: 'Tarifa actual', val: data.system.tarifType },
              ]}
            />
          </Box>
        </CollapsibleCard>
        <CollapsibleCard heading="Condiciones de crédito" pad="small" half>
          <DataTable
            columns={[{ property: 'head' }, { property: 'val' }]}
            pad={{ horizontal: 'medium', vertical: 'small' }}
            background={{ body: ['#fff', '#f6f6f6'] }}
            data={[
              { head: 'Enganche', val: currency.format(data.loan.hookTotal) },
              {
                head: 'Capital a financiar',
                val: currency.format(data.loan.total),
              },
              {
                head: 'Comisión por apertura c. IVA',
                val: currency.format(data.loan.openingCommission),
              },
              {
                head: 'Pago mensual',
                val: currency.format(data.loan.monthlyPayment),
              },
            ]}
          />
        </CollapsibleCard>
      </ResponsiveBox>
      <ResponsiveBox gap="small" direction="column">
        <CollapsibleCard heading="Financiamiento" half pad="small">
          <DataTable
            columns={[{ property: 'head' }, { property: 'val' }]}
            pad={{ horizontal: 'medium', vertical: 'small' }}
            background={{ body: ['#fff', '#f6f6f6'] }}
            data={[
              { head: 'Plazo', val: `${data.loan.monthlyTerm / 12} años` },
              {
                head: 'Tasa fija de interés ordinaria anual',
                val: `${Number(data.loan.annualInterestRate * 100).toFixed(
                  2,
                )}%`,
              },
              {
                head: 'Comisión por apertura',
                val: `${Number(
                  data.loan.openingCommissionPercentage * 100,
                ).toFixed(2)}%`,
              },
              {
                head: 'Enganche',
                val: `${Number(data.loan.hookPercentage).toFixed(2)}%`,
              },
              { head: 'Pagos fijos mensuales' },
              { head: 'Sin penalización por pagos anticipados' },
            ]}
          />
        </CollapsibleCard>
        {/* {JSON.stringify(data.savings.yearly[0])} */}
        <CollapsibleCard heading="Ahorros" half pad="small">
          <DataTable
            columns={[{ property: 'head' }, { property: 'val' }]}
            pad={{ horizontal: 'medium', vertical: 'small' }}
            background={{ body: ['#fff', '#f6f6f6'] }}
            data={[
              {
                head: 'Costo de la electricidad anual CFE',
                val: currency.format(data.savings.yearly[0].electricityCost),
              },
              {
                head: 'Pagos anuales de crédito',
                val: currency.format(data.savings.yearly[0].annualPayment),
              },
              {
                head: 'Ahorro anual',
                val: currency.format(
                  data.savings.yearly[0].electricityCost -
                    data.savings.yearly[0].annualPayment,
                ),
              },
              {
                head: 'Rendimiento total aproximado',
                val: currency.format(data.savings.totalApproximateSavings),
              },
              {
                head: 'Rendimiento primer año',
                val: `${(data.savings.yearly[0].investmentPerformancePercentage
                  .toFixed &&
                  data.savings.yearly[0].investmentPerformancePercentage.toFixed(
                    2,
                  )) ||
                  data.savings.yearly[0]
                    .investmentPerformancePercentage}${typeof data.savings
                  .yearly[0].investmentPerformancePercentage !== 'string' &&
                  '%'}`,
              },
              {
                head: 'Rendimiento promedio 8 años',
                val: `${(data.savings.average8YearsPerformancePercentage
                  .toFixed &&
                  data.savings.average8YearsPerformancePercentage.toFixed(2)) ||
                  data.savings.average8YearsPerformancePercentage}${typeof data
                  .savings.average8YearsPerformancePercentage !== 'string' &&
                  '%'}`,
              },

              {
                head: 'Crecimiento del precio CFE',
                val: `${CFE_INCREASE_RATE * 100}%`,
              },
              {
                head: 'CELs (pesos*MWh)',
                val: currency.format(CFE_TARIFS.CELS),
              },
            ]}
          />
        </CollapsibleCard>
      </ResponsiveBox>
      <CollapsibleCard heading="Flujos anuales" pad="small">
        <AnnualFlow {...data.loan} {...data.system} savings={data.savings} />
      </CollapsibleCard>
      <CollapsibleCard heading="Tabla de amortización" pad="small">
        <AmortizatonTable {...data.loan} personType={data.client.personType} />
      </CollapsibleCard>
      <Text>
        Apoyo Cotidiano, S.A. de C.V., SOFOM, E.N.R. Documento con fines
        informativos y no obliga en nigún caso proveer algún servicio por parte
        de la SOFOM. Las condiciones mostradas en este documento pueden cambiar
        sin previo aviso. *Calculado sobre el monto del crédito más el IVA.
        **Costo anual total (CAT) de financiamiento expresado en términos
        porcentuales anuales, para fines informativos y de comparación,
        incorpora la totalidad de los costos y gastos inherentes a los créditos,
        préstamos o financiamientos que otorgan las Entidades Financieras, de
        conformidad con las disposiciones que al efecto emita el Banco de
        México. CAT sin IVA 27.45%. Para fines informativos y de comparación
        Contratar créditos que excedan tu capacidad de pago afecta tu historial
        crediticio.
      </Text>
      {!quote || typeof quote !== 'object' ? (
        <Box pad={{ vertical: 'medium' }}>
          <Button
            disabled={quote === 'LOADING'}
            label={quote === 'LOADING' ? 'Cargando...' : 'Generar cotización'}
            onClick={handleCreateQuote}
            primary
          />
          {quote === 'ERROR' && (
            <Paragraph style={{ color: '#D84D40' }}>
              Error, verificar datos e intentar de nuevo
            </Paragraph>
          )}
        </Box>
      ) : (
        <Box
          gap="medium"
          pad="medium"
          align="center"
          justify="around"
          direction="row"
        >
          <Button
            disabled={sendEmailStatus === 'LOADING'}
            label={sendEmailStatus === 'LOADING' ? 'CARGANDO...' : 'ENVIAR'}
            onClick={handleSendQuote}
          />
          <Button
            as="a"
            href={`${window.origin}/api/v1/quotes/${
              quote.id
            }.pdf?authorization=${localStorage.getItem('@idToken')}`}
            label="DESCARGAR"
            target="_blank"
            rel="noopener noreferrer"
            primary
          />
        </Box>
      )}
    </Box>
  )
}

Overview.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Overview
