import React, { useState } from 'react'
import { Box, Heading } from 'grommet'

import Form from './Form'
import Overview from './Overview'

function Quote() {
  const [data, setData] = useState({})
  const [step, setStep] = useState(0)

  function handleCalculate({ client, system, loan, savings }) {
    setData({ client, system, loan, savings })
    setStep(1)
  }

  function handleGoBack() {
    setStep(0)
  }

  function handleResetQuote() {
    setData()
    setStep(0)
  }

  function handleResetQuoteSameClient() {
    setData((prev) => ({
      system: prev.system,
      client: prev.client,
    }))

    setStep(0)
  }

  return (
    <Box pad="large">
      <Heading>Cotizador</Heading>
      {step === 0 && <Form data={data} onCalculate={handleCalculate} />}
      {step === 1 && (
        <Overview
          onResetQuoteSameClient={handleResetQuoteSameClient}
          onResetQuote={handleResetQuote}
          onGoBack={handleGoBack}
          data={data}
        />
      )}
    </Box>
  )
}

export default Quote
