import Big from 'big.js'

import { IVA_MULTIPLIER } from './constants'

function getMonthlyPayment({
  balance: balanceSmall = 0,
  annualInterest: anualInterestSmall = 0,
  months = 0,
  personType = 0,
}) {
  try {
    let annualInterest = Big(anualInterestSmall)

    if (personType === 'pf') {
      annualInterest = annualInterest.times(IVA_MULTIPLIER)
    }

    const balance = Big(balanceSmall)
    const monthlyInterestRate = annualInterest.div(12)

    const monthlyPayment = monthlyInterestRate.times(balance).times(
      monthlyInterestRate
        .plus(1)
        .pow(months)
        .div(
          monthlyInterestRate
            .plus(1)
            .pow(months)
            .minus(1),
        ),
    )

    // Return fixed numeber .XXXX
    return Number(monthlyPayment.toFixed(4))
  } catch (error) {
    return 0
  }
}

export { getMonthlyPayment }
