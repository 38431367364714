import React, { useContext, useState } from 'react'
import { Box, Button, Heading, FormField, TextInput } from 'grommet'

import { UserContext } from '../Main'
import api from '../../utils/api'

function Profile() {
  const user = useContext(UserContext)

  const [fields, setFields] = useState({
    name: user.name,
    email: user.email,
    updated: false,
    loading: false,
  })

  function handleChange({ target: { name, value } }) {
    setFields((prev) => ({ ...prev, [name]: value, updated: true }))
  }

  function handleSave() {
    setFields((prev) => ({ ...prev, loading: true }))

    api
      .patchUser(user.user_id, { name: fields.name })
      .then(({ user: userData }) =>
        setFields({
          name: userData.name,
          email: userData.email,
          updated: false,
          loading: false,
        }),
      )
      .catch(() => setFields((prev) => ({ ...prev, loading: false })))
  }

  return (
    <Box pad="large">
      <Box direction="row" justify="between" align="center">
        <Heading>Mi perfil</Heading>
      </Box>
      <FormField label="Nombre completo">
        <TextInput
          onChange={handleChange}
          name="name"
          value={fields.name}
          placeholder="Nombre completo"
        />
      </FormField>
      <FormField label="Correo">
        <TextInput
          onChange={handleChange}
          name="email"
          value={fields.email}
          placeholder="Correo"
          readOnly
        />
      </FormField>
      {fields.updated && (
        <Box direction="row" justify="end" gap="medium">
          <Button label="Cancelar" />
          <Button
            onClick={handleSave}
            label={fields.loading ? 'Cargando...' : 'Guardar'}
            disabled={fields.loading}
            primary
          />
        </Box>
      )}
      {/* <Text>{JSON.stringify(user)}</Text> */}
    </Box>
  )
}

export default Profile
