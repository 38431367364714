import styled from 'styled-components'
import { Box } from 'grommet'

const StyledLoginContainer = styled(Box)`
  @media (min-width: 600px) {
    flex-direction: row;
  }
`

export { StyledLoginContainer }
