import React, { useState } from 'react'
import { Box, Heading } from 'grommet'

import { StyledCollapsible } from './styled'

function CollapsibleCard({ children, heading, half, ...props }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <StyledCollapsible
      {...props}
      round="xsmall"
      elevation="xsmall"
      expanded={expanded}
      half={half}
      pad={0}
    >
      <Box pad="medium" width={{ min: 'auto' }} height={{ min: 'auto' }}>
        <Heading
          style={{ cursor: 'pointer', margin: 0 }}
          onClick={() => setExpanded(!expanded)}
          level="3"
        >
          {heading}
        </Heading>
      </Box>
      <Box overflow="scroll" flex={{ grow: 1, shrink: 1 }}>
        {children}
      </Box>
    </StyledCollapsible>
  )
}

export default CollapsibleCard
