import React from 'react'
import styled from 'styled-components'

const SandwitchContainer = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 16px 20px;
  margin-bottom: -16px;
  position: relative;

  span {
    left: 0;
    top: 2px;
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: ${({ white }) => (white ? '#fff' : '#4a60e4')};
    &:nth-child(1) {
      transform: ${({ closed }) =>
        closed && 'rotate(45deg) translate(7px, 8px);'};
    }
    &:nth-child(2) {
      top: 12px;
      display: ${({ closed }) => closed && 'none'};
    }
    &:nth-child(3) {
      top: 22px;
      transform: ${({ closed }) =>
        closed && 'rotate(-45deg) translate(5px, -7px)'};
    }
  }

  @media (min-width: 600px) {
    display: none;
  }
`

function Sandwitch(props) {
  return (
    <SandwitchContainer {...props}>
      <span />
      <span />
      <span />
    </SandwitchContainer>
  )
}

export default Sandwitch
