import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Box, Text } from 'grommet'
import auth0 from 'auth0-js'
import styled from 'styled-components'
import LogRocket from 'logrocket'

import webAuth from '../../utils/auth0'
import bugsnag from '../../utils/bugsnag'

import Quotes from '../Quotes'
import Clients from '../Clients'
import Users from '../Users'
import User from '../User'
import Profile from '../Profile'

import Nav from '../../components/Nav'
import Sandwitch from '../../components/Sandwitch'

const UserContext = React.createContext(undefined)

const StyledContent = styled(Box)`
  @media (min-width: 600px) {
    padding-left: 250px;
  }
`

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN

function Main() {
  const [user, setUser] = useState(undefined)
  const [menuHidden, setMenuHidden] = useState(true)

  useEffect(() => {
    function getIsAuthenticated() {
      try {
        const accessToken = window.localStorage.getItem('@accessToken')

        console.log({ accessToken })

        webAuth.client.userInfo(accessToken, (error, data) => {
          if (data) {
            bugsnag.leaveBreadcrumb('Got user data from webAuth', data)

            webAuth.checkSession(
              {
                audience: 'https://suneco.auth0.com/api/v2/',
                scope: 'read:current_user update:current_user_metadata',
                responseType: 'token',
              },
              (sessionError, { accessToken: sessionAccessToken } = {}) => {
                if (sessionAccessToken) {
                  bugsnag.leaveBreadcrumb('Got session token')

                  const auth0Manage = new auth0.Management({
                    domain: AUTH0_DOMAIN,
                    token: sessionAccessToken,
                  })

                  auth0Manage.getUser(data.sub, (manageError, userData) => {
                    if (userData) {
                      bugsnag.leaveBreadcrumb('Got uerData from auth0Manage')

                      LogRocket.identify(userData.user_id, {
                        name: userData.name,
                        email: userData.email,
                      })

                      setUser(userData)
                    } else {
                      bugsnag.notify(
                        manageError || new Error('Empty auth0Manage error'),
                      )

                      setUser(false)
                    }
                  })
                } else {
                  bugsnag.notify(
                    sessionError || new Error('Empty check session error'),
                  )
                  setUser(false)
                }
              },
            )
          } else {
            bugsnag.notify(error)
            setUser(false)
          }
        })
      } catch (error) {
        bugsnag.notify(error)
        setUser(false)
      }
    }

    getIsAuthenticated()
  }, [])

  if (user === undefined) {
    return (
      <Box
        height="100%"
        background={{ color: 'brand' }}
        flex={{ grow: 1 }}
        align="center"
        justify="center"
      >
        <Text color="#fff">Cargando...</Text>
      </Box>
    )
  }

  if (user === false) {
    console.log('REDIRECT TO LOGIN')
    return <Redirect to="/log-in" />
  }

  const isAdmin = user.app_metadata && user.app_metadata.admin

  function toggleMenu() {
    setMenuHidden((prev) => !prev)
  }

  return (
    <UserContext.Provider value={user}>
      <Box direction="row" height={{ min: '100%' }}>
        <Nav
          onClose={toggleMenu}
          hidden={menuHidden}
          isAdmin={isAdmin}
          user={user}
        />
        <StyledContent flex={{ grow: 1 }}>
          <Sandwitch onClick={toggleMenu} />
          <Switch>
            <Route path="/quotes" component={Quotes} />
            <Route path="/installers" component={Users} exact />
            <Route path="/installers/:id" component={User} />
            <Route path="/clients" component={Clients} />
            <Route path="/profile" component={Profile} />
            <Route
              render={() => (
                <Redirect to={isAdmin ? '/installers' : '/quotes'} />
              )}
            />
          </Switch>
        </StyledContent>
      </Box>
    </UserContext.Provider>
  )
}

export { UserContext }

export default Main
