import React from 'react'
import styled, { keyframes } from 'styled-components'

const skCubemove = keyframes`
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
  }
`

const StyledSpinner = styled.div`
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
`

const Cube = styled.div`
  background-color: rgb(74, 96, 228);
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${skCubemove} 1.8s infinite ease-in-out;
`

const Cube2 = styled(Cube)`
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
`

function Spinner() {
  return (
    <StyledSpinner>
      <Cube />
      <Cube2 />
    </StyledSpinner>
  )
}

export default Spinner
