import ky from 'ky'

const HOST = window.origin

let inMemoryToken
function getToken() {
  if (!inMemoryToken) {
    inMemoryToken = localStorage.getItem('@idToken')
  }

  return inMemoryToken
}

const api = ky
  .create({
    prefixUrl: `${HOST}/api/v1`,
    timeout: 20000,
  })
  .extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set('Authorization', `Bearer ${getToken()}`)
        },
      ],
      afterResponse: [
        (request, options, response) => {
          if (response.status === 401) {
            // WARN: Redirect to login
            window.location.replace(
              `${window.origin}/log-in?error=not_authorized`,
            )
            window.location.reload(true)
          }
        },
      ],
    },
  })

export default {
  createUser: ({ name, email, phoneNumber, company }) =>
    api.post('users', {
      json: {
        name,
        email,
        phoneNumber,
        company,
      },
    }),
  getUsers: () => api.get('users').json(),
  getUser: (id) => api.get(`users/${id}`).json(),
  patchUser: (id, { name, blocked, company, phone }) =>
    api
      .patch(`users/${id}`, { json: { name, blocked, company, phone } })
      .json(),
  setQuote: (data) => api.post('quotes', { json: data }).json(),
  sendQuote: (quoteId) => api.post(`quotes/${quoteId}/send`).json(),
  getQuote: (quoteId) => api.get(`quotes/${quoteId}`).json(),
  getClients: () => api.get(`clients`).json(),
  getQuotes: ({ user: userId = null } = {}) =>
    api.get(`quotes`, userId ? { searchParams: { user: userId } } : {}).json(),
  getAddressFrom: ({ zipCode }) =>
    ky
      .get(`https://cp-mexico.herokuapp.com/v2/codigo_postal/${zipCode}`)
      .json(),
}
