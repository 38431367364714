import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Grommet } from 'grommet'
import { createGlobalStyle } from 'styled-components'

import LogRocket from 'logrocket'

import bugsnagClient from './utils/bugsnag'
import LogIn from './containers/LogIn'
import Main from './containers/Main'

import theme from './theme.json'

if (
  process.env.REACT_APP_LOG_ROCKET_CLIENT_ID &&
  process.env.NODE_ENV !== 'development'
) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_CLIENT_ID)
}

const Auth0 = React.createContext(null)

const ErrorBoundary = bugsnagClient.getPlugin('react')

const GlobalStyle = createGlobalStyle`
  table {
    min-width: 100%;
  }

  table, th {
    height: auto !important;
  }

  thead {
    height: 1px !important;
  }
`

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <GlobalStyle />
        <Grommet theme={theme} full>
          <Switch>
            <Route exact path="/log-in" component={LogIn} />
            <Route path="/" component={Main} />
          </Switch>
        </Grommet>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export { Auth0 }

export default App
