import React from 'react'
import { Box, Button, Image } from 'grommet'
import { Group, Configure, Calculator, Logout } from 'grommet-icons'
import { NavLink, Link } from 'react-router-dom'
import styled from 'styled-components'

import webAuth from '../../utils/auth0'
import sunEco from '../../assets/suneco.svg'
import Sandwitch from '../../components/Sandwitch'

const StyledNav = styled(Box)`
  position: fixed;
  transform: ${({ hidden }) => `translateX(${hidden ? '-100%' : '0'})`};
  transition: 0.3s transform;
  z-index: 100;

  li a > div {
    display: flex;
    justify-content: flex-start;
    padding: 8px 20px;
    text-align: left;
    font-size: 1.1rem;
  }

  li a.active > div {
    font-weight: bold;
  }

  @media (min-width: 600px) {
    transform: none;
  }
`

function Nav({ isAdmin, user, hidden, onClose }) {
  function handleLogout() {
    webAuth.logout({ returnTo: `${window.origin}/log-in` })
  }

  return (
    <StyledNav
      hidden={hidden}
      style={{ position: 'absolute', top: 0, left: 0, width: 250 }}
      flex={{ shrink: 0 }}
      background={{ color: 'brand' }}
      height={{ min: '100%' }}
      pad={{ vertical: 'large' }}
    >
      <Sandwitch
        style={{ marginTop: 0, marginBottom: 12 }}
        onClick={onClose}
        closed
        white
      />
      <Box pad="medium">
        <Link to="/">
          <Image width="150px" src={sunEco} alt="Sun Eco" />
        </Link>
      </Box>
      <Box pad="0" margin={{ top: 'large' }} flex={{ grow: 1 }} as="ul">
        {isAdmin ? (
          <Box as="li">
            <Button
              onClick={onClose}
              to="/installers"
              label="Instaladores"
              color="#fff"
              icon={<Configure />}
              as={NavLink}
              hoverIndicator
              plain
            />
          </Box>
        ) : (
          <Box as="li">
            <Button
              onClick={onClose}
              to="/quotes"
              label="Cotizador"
              icon={<Calculator />}
              color="#fff"
              as={NavLink}
              hoverIndicator
              plain
            />
          </Box>
        )}
        <Box as="li">
          <Button
            onClick={onClose}
            to="/clients"
            label="Clientes"
            color="#fff"
            icon={<Group />}
            as={NavLink}
            hoverIndicator
            plain
          />
        </Box>
      </Box>
      <Box pad="0" as="ul">
        <Box as="li">
          <Button
            onClick={handleLogout}
            to="#"
            label="Cerrar sesión"
            icon={<Logout />}
            color="#fff"
            as="a"
            hoverIndicator
            plain
          />
        </Box>
        <Box as="li">
          <Button
            onClick={onClose}
            color="#fff"
            to="/profile"
            icon={
              <img
                style={{ borderRadius: '50%', height: '30px' }}
                src={user.picture}
                alt=""
              />
            }
            label="Mi perfil"
            as={NavLink}
            hoverIndicator
            plain
          />
        </Box>
      </Box>
    </StyledNav>
  )
}

export default Nav
