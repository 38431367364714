import React, { useState, useEffect } from 'react'
import {
  Box,
  Heading,
  TextInput,
  Button,
  Form,
  Image,
  Layer,
  Paragraph,
  CheckBox,
} from 'grommet'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'

import auth0 from '../../utils/auth0'
import sunecoGliph from '../../assets/suneco-gliph.svg'

import { StyledLoginContainer } from './styled'
import background from './background-one.jpg'

const getResponseInitialState = () => {
  const { hash } = window.location

  return {
    emailResetSent: false,
    loading: Boolean(hash),
    error: null,
  }
}

const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    flex-direction: column;
    button {
      width: 100%;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > div {
      flex-direction: column;
      button {
        width: auto;
      }
    }
  }
`

function LogIn() {
  const { hash, search } = window.location
  const { email = localStorage.getItem('@savedUser') || '' } =
    queryString.parse(search) || {}

  const history = useHistory()

  const [fields, setFields] = useState({ email, password: '' })
  const [isPasswordReset, setIsPasswordReset] = useState(false)
  const [rememberUser, setRememberUser] = useState(
    Boolean(localStorage.getItem('@savedUser')),
  )
  const [response, setResponse] = useState(getResponseInitialState())

  useEffect(() => {
    if (hash) {
      auth0.parseHash((error, result) => {
        if (result) {
          localStorage.setItem('@accessToken', result.accessToken)
          localStorage.setItem('@idToken', result.idToken)

          history.replace('/', {})
        } else {
          console.log(error)
        }

        setResponse({ loading: false, error: null })
      })
    } else {
      localStorage.removeItem('@accessToken')
      localStorage.removeItem('@idToken')
    }
  }, [history, hash])

  function handleLogIn(event) {
    event.preventDefault()

    setResponse({ loading: true, error: null })

    if (rememberUser) localStorage.setItem('@savedUser', fields.email)
    else localStorage.removeItem('@savedUser')

    isPasswordReset
      ? auth0.changePassword(
          {
            connection: 'Username-Password-Authentication',
            email: fields.email,
          },
          (error, response) => {
            if (error) {
              localStorage.removeItem('@accessToken')
              localStorage.removeItem('@idToken')

              setResponse({ emailResetSent: false, loading: null, error })
            } else {
              setResponse({
                emailResetSent: true,
                loading: false,
                error: null,
              })
            }
          },
        )
      : auth0.login(
          {
            email: fields.email,
            password: fields.password,
            realm: 'Username-Password-Authentication',
            responseType: 'token id_token',
          },
          (error) => {
            if (error) {
              setResponse({ loading: null, error })
            }
          },
        )
  }

  function handleChange({ target: { name, value } }) {
    setFields((prev) => ({ ...prev, [name]: value }))
  }

  function handleSetPasswordReset() {
    setIsPasswordReset((prev) => !prev)
  }

  function handleDismissSuccess() {
    setResponse(getResponseInitialState())
    setIsPasswordReset(false)
  }

  return (
    <StyledLoginContainer height="100%" direction="column">
      {response.emailResetSent && (
        <Layer
          onEsc={handleDismissSuccess}
          onClickOutside={handleDismissSuccess}
        >
          <Box pad="medium">
            <Heading level={4}>Correo de restauración enviado</Heading>
            <Paragraph>
              Revisa tu bandeja de entrada, si es necesario también en "No
              deseado".
            </Paragraph>
            <Button label="OK" onClick={handleDismissSuccess} primary />
          </Box>
        </Layer>
      )}
      <Box width={{ min: '50%' }} background={`url(${background})`} />
      <Box
        justify="center"
        align="stretch"
        pad={{ vertical: '16px', horizontal: '24px' }}
        flex={{ grow: 1 }}
        gap="medium"
      >
        <Image width={60} height={60} src={sunecoGliph} />
        <Heading color="brand" size="1.5rem" level="1">
          {isPasswordReset ? 'Restaurar contraseña' : 'Iniciar sesión'}
        </Heading>
        <Form>
          <Box gap="8px">
            <TextInput
              value={fields.email}
              name="email"
              onChange={handleChange}
              disabled={response.loading}
              placeholder="Correo"
              required
            />
            {!isPasswordReset && (
              <TextInput
                value={fields.password}
                name="password"
                type="password"
                onChange={handleChange}
                disabled={response.loading}
                placeholder="Contraseña"
                required
              />
            )}
            <ResponsiveBox>
              {!isPasswordReset && (
                <CheckBox
                  checked={rememberUser}
                  label="Recordar mi cuenta"
                  onChange={() => setRememberUser((prev) => !prev)}
                />
              )}
              <Box margin={{ left: 'auto' }}>
                <Button
                  type="submit"
                  onClick={handleLogIn}
                  margin={{ top: '12px', bottom: '8px' }}
                  label={
                    response.loading
                      ? 'Cargando'
                      : isPasswordReset
                      ? 'Restaurar'
                      : 'Iniciar sesión'
                  }
                  disabled={response.loading}
                  alignSelf="end"
                  primary
                />
                <Button
                  onClick={handleSetPasswordReset}
                  label={
                    isPasswordReset
                      ? 'Recordé mi contraseña'
                      : '¿Olvidaste tu contraseña?'
                  }
                  plain
                />
              </Box>
            </ResponsiveBox>
            <Paragraph color="#D84D40" alignSelf="end">
              {response.error ? 'Error al iniciar sesión' : ' '}
            </Paragraph>
          </Box>
        </Form>
      </Box>
    </StyledLoginContainer>
  )
}

export default LogIn
